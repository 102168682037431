import React, { useState } from "react";
import { Tabs } from "@avalara/skylab-react";
import { useLocation, useParams } from "react-router-dom";
import { Tenants } from "./tenants";
import { Users } from "./users";
import { Applications } from "./applications";
import { Groups } from "./groups";
import { v4 as uuidv4 } from "uuid";

export function OrganizationDetails() {
  const params = useParams();
  const location = useLocation();
  const { orgName } = location?.state ? location.state : [];
  const [selectedTab, setSelectedTab] = useState("");

  const tabItems = [
    {
      id: "org-tenants",
      path: "/org/tenants",
      label: "Tenants",
      tabPanelId: "tabpanel-tenants",
      selected: selectedTab === "tenants",
    },
    {
      id: "org-users",
      path: "/org/users",
      label: "Users",
      tabPanelId: "tabpanel-users",
      selected: selectedTab === "users",
    },
    {
      id: "org-apps",
      path: "/org/apps",
      label: "Apps",
      tabPanelId: "tabpanel-apps",
      selected: selectedTab === "apps",
    },
    {
      id: "org-groups",
      path: "/org/groups",
      label: "groups",
      tabPanelId: "tabpanel-groups",
      selected: selectedTab === "groups",
    },
  ];
  const navigateTo = (to) => {
    if (to.includes("tenants")) {
      setSelectedTab("tenants");
    } else if (to.includes("users")) {
      setSelectedTab("users");
    } else if (to.includes("apps")) {
      setSelectedTab("apps");
    } else if (to.includes("groups")) {
      setSelectedTab("groups");
    } else {
      setSelectedTab("tenants");
    }
  };

  return (
    <>
      <h3>{orgName}</h3>
      <hr />
      <React.Fragment>
        <Tabs
          tabItems={tabItems}
          key={uuidv4()}
          onSSelect={(e) => navigateTo(e.detail.id)}
        />
        {selectedTab === "tenants" ? <Tenants id={params.id} /> : null}
        {selectedTab === "users" ? <Users id={params.id} /> : null}
        {selectedTab === "apps" ? <Applications id={params.id} /> : null}
        {selectedTab === "groups" ? <Groups id={params.id} /> : null}
      </React.Fragment>
    </>
  );
}
