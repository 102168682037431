import axios from "axios";
import { getJwtToken } from "./app/sessionSlice";
const axiosInstance = axios.create();
export default axiosInstance;

axios.interceptors.request.use(
  (config) => {
    const token = getJwtToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // const originalRequest = error.config;

    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === "http://Localhost:3000/auth/token"
    // ) {
    //router.push('/login')
    //   return Promise.reject(error);
    // }

    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const refreshToken = getJwtToken();
    //   return axios
    //     .post("/auth/token", {
    //       refresh_token: refreshToken,
    //     })
    //     .then((res) => {
    //       if (res.status === 201) {
    //         axios.defaults.headers.common["Authorization"] =
    //           "Bearer " +
    //           "8d81f51f55ad1f3fbedde871b6fef014784727f1bdae9ca1ebf5189ab89d1f67";
    //         return axios(originalRequest);
    //       }
    //     });
    // }
    return Promise.reject(error);
  }
);
