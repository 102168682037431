import React, { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return <p>In logout page</p>;
};

export default Logout;
