import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    isLoading: false,
    isAuthenticated: false,
    accessToken: "",
    code: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCode: (state, action) => {
      console.log("inside set code", action.payload);
      state.code = action.payload;
    },
    setData: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.accessToken = action.payload.accessToken;
    },
  },
});

export const { setLoading, setData, setCode } = sessionSlice.actions;

export function selectSession(session) {
  return session;
}

export function selectSessionLoading(session) {
  return session.isLoading;
}

export function getJwtToken() {
  return sessionStorage.getItem("jwt");
}

export function setJwtToken(token) {
  sessionStorage.setItem("jwt", token);
}

export function getRefreshToken() {
  return sessionStorage.getItem("refreshToken");
}

export function setRefreshToken(token) {
  sessionStorage.setItem("refreshToken", token);
}

export const getSessionAsync =
  (refresh = false) =>
  async (dispatch, getState) => {
    const { session } = getState();
    if (session.isAuthenticated || session.code) {
      return;
    }

    dispatch(setLoading(true));

    const scopes = process.env.REACT_APP_SCOPE.replaceAll(",", " ");
    const port = window.location.port === "" ? "" : `:${window.location.port}`;
    const redirect_uri =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      "/auth/callback";

    const authUrl = new URL(
      `https://${process.env.REACT_APP_IDENTITY_HOST}/${process.env.REACT_APP_LOGIN_URL}`
    );
    authUrl.searchParams.append("response_type", "code");
    authUrl.searchParams.append("client_id", process.env.REACT_APP_CLIENT_ID);
    authUrl.searchParams.append("scope", scopes);
    authUrl.searchParams.append("redirect_uri", redirect_uri);

    window.location = authUrl.href;
  };

export const handleLogin = () => async (dispatch, getState) => {
  const { session } = getState();
  const { code } = session;

  if (code && !getJwtToken("jwt")) {
    const port = window.location.port === "" ? "" : `:${window.location.port}`;
    const redirect_uri =
      window.location.protocol +
      "//" +
      window.location.hostname +
      port +
      "/auth/callback";

    const data = {
      grant_type: "authorization_code",
      code: code,
      redirect_uri: redirect_uri,
      client_id: "d55dac3210774a4c98baa92f27abc8c7",
    };

    dispatch(setLoading(true));

    const getToken = await axios.post(
      "https://ai-awscqa.avlr.sh/connect/token",
      data,
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );

    setJwtToken(getToken.data.access_token);
    dispatch(
      setData({
        accessToken: getToken.data.access_token,
        isAuthenticated: true,
      })
    );
    dispatch(setLoading(false));
  } else {
    return;
  }
};
export default sessionSlice.reducer;
