import { v4 as uuid } from 'uuid';

function useId() {
  let current = uuid();

  function getCurrentId() {
    return current;
  }

  function getNextId() {
    current = uuid();
    return getCurrentId();
  }

  return [getCurrentId, getNextId];
}

export default useId;
