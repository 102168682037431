import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "../app/sessionSlice";
import systemReducer from "../app/systemSlice";
import organizationReducer from "../app/organizationSlice";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    system: systemReducer,
    organization: organizationReducer,
  },
});
