import { createSlice } from "@reduxjs/toolkit";
import { buildURL } from "../shared/Utils";
import axios from "../axios";
import { getJwtToken } from "../app/sessionSlice";

export const systemSlice = createSlice({
  name: "system",
  initialState: {
    isloading: false,
    searchField: "",
    page: {
      data: [],
      paginate: {
        pageNumber: 0,
        startIndex: -1,
        endIndex: 0,
        rowsPerPage: 0,
        totalRecords: 0,
      },
    },
    sortColumn: ["title", true],
    noDataType: "",
  },

  reducers: {
    setLoading: (state, action) => {
      state.isloading = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageToNull: (state) => {
      state.page = {
        data: [],
        paginate: {
          pageNumber: 0,
          startIndex: -1,
          endIndex: 0,
          rowsPerPage: 0,
          totalRecords: 0,
        },
      };
    },
    setNoDataType: (state, action) => {
      state.noDataType = action.payload;
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
  },
});

export const {
  setLoading,
  setPage,
  setPageToNull,
  setSortColumn,
  setNoDataType,
  setSearchField,
} = systemSlice.actions;

export const selectPage = (state) => {
  return state.system.page;
};

export const selectSortColumn = (state) => {
  return state.system.sortColumn;
};

export const selectIsLoading = (state) => {
  return state.system.isloading;
};

export const selectFormState = (state) => {
  return state.system.formState;
};

export const selectNoData = (state) => {
  return state.system.noDataType;
};
export const selectSearchField = (state) => {
  return state.system.searchField;
};

export const fetchSystemsAPI =
  (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().system;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (searchField) {
      filter = `namespace eq '${searchField.trim()}'`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/systems/?%24count=true&`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },

      withCredentials: false,
    });

    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setLoading(false));
  };

export const fetchSystemsDetailsByIDAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState();
    const { isloading } = localState.system;
    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/systems/${id}?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });
    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setLoading(false));
  };

export const fetchSystemsRolesAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState();
    const { isloading } = localState.system;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    // if (searchField) {
    //   filter = `displayName%20eq%20${searchField}`;
    // }

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/systems/${id}/roles?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });
    // console.log(
    //   Object.values(
    //     response?.data?.items?.reduce((a, { displayName, data }) => {
    //       if (!a[displayName]) a[displayName] = { displayName, data: [] };
    //       console.log("ad", a[displayName]);
    //       a[displayName]?.data?.push(data[0]);
    //       return a;
    //     }, {})
    //   )
    // );

    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setLoading(false));
  };
export default systemSlice.reducer;
