import { createSlice } from "@reduxjs/toolkit";
import { buildURL } from "../shared/Utils";
import axios from "../axios";
import { getJwtToken } from "../app/sessionSlice";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    isloading: false,
    isTenantsLoading: false,
    isUsersLoading: false,
    isAppsLoading: false,
    isGroupsLoading: false,
    searchField: "",
    page: {
      data: [],
      paginate: {
        pageNumber: 0,
        startIndex: -1,
        endIndex: 0,
        rowsPerPage: 0,
        totalRecords: 0,
      },
    },
    sortColumn: ["id", true],
    noDataType: "",
  },

  reducers: {
    setLoading: (state, action) => {
      state.isloading = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageToNull: (state) => {
      state.page = {
        data: [],
        paginate: {
          pageNumber: 0,
          startIndex: -1,
          endIndex: 0,
          rowsPerPage: 0,
          totalRecords: 0,
        },
      };
    },
    setNoDataType: (state, action) => {
      state.noDataType = action.payload;
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setTenantsLoading: (state, action) => {
      state.isTenantsLoading = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.isUsersLoading = action.payload;
    },
    setAppsLoading: (state, action) => {
      state.isAppsLoading = action.payload;
    },
    setGroupsLoading: (state, action) => {
      state.isGroupsLoading = action.payload;
    },
  },
});

export const {
  setLoading,
  setPage,
  setPageToNull,
  setSortColumn,
  setNoDataType,
  setSearchField,
  setTenantsLoading,
  setUsersLoading,
  setAppsLoading,
  setGroupsLoading,
} = organizationSlice.actions;

export const selectPage = (state) => {
  return state.organization.page;
};

export const selectSortColumn = (state) => {
  return state.organization.sortColumn;
};

export const selectIsLoading = (state) => {
  return state.organization.isloading;
};

export const selectFormState = (state) => {
  return state.organization.formState;
};

export const selectNoData = (state) => {
  return state.organization.noDataType;
};
export const selectSearchField = (state) => {
  return state.organization.searchField;
};

export const selectIsTenantsLoading = (state) => {
  return state.organization.isTenantsLoading;
};

export const selectIsUsersLoading = (state) => {
  return state.organization.isUsersLoading;
};

export const selectIsAppsLoading = (state) => {
  return state.organization.isAppsLoading;
};

export const selectIsGroupsLoading = (state) => {
  return state.organization.isGroupsLoading;
};

export const fetchOrganizationsAPI =
  (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (searchField) {
      filter = `displayName eq '${searchField.trim()}'`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/organizations?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });

    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setLoading(false));
  };

export const fetchOrganizationByIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/organizations/${id}?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });

    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data,
          paginate: paginateData,
        })
      );
    }

    dispatch(setLoading(false));
  };

export const fetchOrganizationByNameAPI =
  (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (searchField) {
      filter = `displayName%20eq%20${searchField}`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/organizations`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });

    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }
    dispatch(setLoading(false));
  };

export const fetchTenantsByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isTenantsLoading } = localState;

    if (isTenantsLoading) {
      return;
    }
    dispatch(setTenantsLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/organizations/${id}/tenants?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });

    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setTenantsLoading(false));
  };

export const fetchUsersByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isUsersLoading } = localState;

    if (isUsersLoading) {
      return;
    }
    dispatch(setUsersLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/organizations/${id}/users?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });
    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };
      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setUsersLoading(false));
  };

export const fetchAppsByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState();
    const { isAppsLoading } = localState.organization;
    if (isAppsLoading) {
      return;
    }
    dispatch(setAppsLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_API_HOST}/organizations/${id}/apps?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    const response = await axios.get(`${apiURI}`, {
      headers: {
        Authorization: `Bearer ${getJwtToken()}`,
      },
      withCredentials: false,
    });
    if (!response?.data) {
      onLanding
        ? dispatch(setNoDataType("onLanding"))
        : dispatch(setNoDataType("onSearch"));
      dispatch(setPageToNull());
    } else {
      dispatch(setNoDataType(""));
      const paginateData = paginateDetails
        ? {
            pageNumber: paginateDetails.currentPage,
            startIndex: paginateDetails.startIndex,
            endIndex: paginateDetails.endIndex,
            rowsPerPage: paginateDetails.rowsPerPage,
            totalRecords: response.data["@recordsetCount"],
          }
        : {
            pageNumber: 1,
            startIndex: 1,
            endIndex: 10,
            rowsPerPage: 20,
            totalRecords: response.data["@recordsetCount"],
          };

      dispatch(
        setPage({
          data: response.data.items,
          paginate: paginateData,
        })
      );
    }

    dispatch(setAppsLoading(false));
  };
export default organizationSlice.reducer;
