import React from "react";
import { Link } from "react-router-dom";

const Overview = () => {
  return (
    <>
      <div className="pad-left-lg">
        <ul className="menu">
          <li>
            <Link to={`/system`}>Systems</Link>
          </li>
          <li>
            <Link to={`/org`}>Organizations</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Overview;
