import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {
  AuiFooter,
  Container,
} from "@avalara/skylab-react";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Container className="container">
      <Provider store={store}>
        <App />
      </Provider>
    </Container>
    <AuiFooter />
  </React.StrictMode>
);

reportWebVitals();
