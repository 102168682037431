export default function gridHeader(
  label,
  property,
  format,
  headerClassName,
  className,
  width,
  alertRowContent,
  sortFunction, // this is required function to execute on sort event
  id // this will be assigned to the sortable column header
) {
  if (
    !(
      typeof label === typeof "" &&
      (typeof property === typeof "" || typeof property === typeof [])
    )
  ) {
    throw new Error("Type of the header is not correct");
  }
  if (headerClassName && typeof headerClassName !== typeof "") {
    throw new Error("Type of the headerClassName is not correct");
  }
  if (className && typeof className !== typeof "") {
    throw new Error("Type of the className is not correct");
  }
  if (width && typeof width !== typeof "") {
    throw new Error("Type of the width is not correct");
  }
  if (sortFunction && typeof sortFunction !== typeof Function) {
    throw new Error("Type of the sortFunction is not correct");
  }
  if (id && typeof id !== typeof "") {
    throw new Error("Type of the id is not correct");
  }
  return {
    label: label,
    property: property,
    format: format ? format : null,
    headerClassName: headerClassName ? headerClassName : null,
    className: className ? className : null,
    width: width ? width : null,
    alertRowContent: alertRowContent ? alertRowContent : null,
    sortFunction: sortFunction ? sortFunction : null,
    id: id ? id : null,
  };
}
