import { useReducer } from "react";

function reduceSelectedAction(state, action) {
    // Component will not re-render if mutated array is returned. This ensures
    // that a new array is created every time.
    const newState = [].concat(state);

    if (action.selected && !newState.includes(action.id)) {
        newState.push(action.id);
    }

    if (!action.selected && newState.includes(action.id)) {
        const index = newState.indexOf(action.id);
        newState.splice(index, 1);
    }

    if (action.onSelectionChange) {
        action.onSelectionChange(newState);
    }

    return newState;
}

function useRowSelection(options, rows, onSelectionChange) {
    const [selected, dispatchSelectedAction] = useReducer(reduceSelectedAction, []);

    function handleSelectAll(event) {
        rows.forEach(row =>
            dispatchSelectedAction({
                id: String(row[options.id]),
                selected: event.target.checked,
                onSelectionChange,
            })
        );
    }

    function handleSelectRow(event) {
        dispatchSelectedAction({
            id: event.target.dataset.rowid,
            selected: event.target.checked,
            onSelectionChange,
        });
    }

    return [selected, handleSelectAll, handleSelectRow];
}

export default useRowSelection;
