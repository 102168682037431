import React, { useCallback, useEffect } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import {
  fetchUsersByOrgIdAPI,
  selectPage,
  setPageToNull,
  selectIsUsersLoading,
} from "../../app/organizationSlice.js";

export function Users(props) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsUsersLoading);
  const page = useSelector(selectPage, shallowEqual);
  let emailsTable = [];

  useEffect(() => {
    if (props?.id) {
      dispatch(fetchUsersByOrgIdAPI(props?.id, 10, null, true));
    }
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch, props.id]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page?.data; //  usersData.items; // page.data;

  const paginate = page?.paginate;

  const columns = [
    gridHeader("Id", "id", (value) => value, null, "", null, null, null, "id"),
    gridHeader("User name", "userName", (value) => value, null, ""),
    gridHeader(
      "Name",
      "displayName",
      (value) => value,
      null,
      "",
      null,
      null,
      null,
      "displayName"
    ),
    gridHeader(
      "Email",
      "emails",
      (value, row) =>
        row?.emails?.length > 1 ? (
          <div>
            <s-table-container class="no-border name-table">
              <table className="row-height-sm borderless">
                <tbody>
                  {Object.keys(row?.emails).map((v, idx) => {
                    emailsTable.push(
                      <tr key={row?.emails[idx]}>
                        <td className="pad-left-none">
                          {row?.emails[idx]?.value}
                        </td>
                      </tr>
                    );

                    const returnMap = emailsTable;
                    emailsTable = [];
                    return returnMap;
                  })}
                </tbody>
              </table>
            </s-table-container>
          </div>
        ) : (
          row?.emails?.length > 0 &&
          row?.emails[0] && <span>{row?.emails[0].value}</span>
        ),
      null,
      ""
    ),
  ];

  let table = "";
  if (loading) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData?.length !== 0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  } else if (pageData?.length === 0 && !loading) {
    table = (
      <React.Fragment>
        <div className="margin-top-sm">
          <div className="font-semibold font-normal text-md">
            No users are found
          </div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div>
      <Row>
        <Col>
          {table}
          {pageData?.length > 0 && !loading ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginate.rowsPerPage}
              totalRecords={paginate.totalRecords}
              startIndex={paginate.startIndex}
              onSPaginate={(e) => {}}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
