import React from 'react';

function AlertRow(props) {
  return (
    <React.Fragment>
      <tr className='row-collapse'>
        <td colSpan={props.colSpan}>
          {props.content}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default AlertRow;
