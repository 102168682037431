import React, { useCallback, useEffect } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { getFormattedDate } from "../../shared/Utils.js";
import {
  fetchSystemsDetailsByIDAPI,
  selectPage,
  selectIsLoading,
} from "../../app/systemSlice.js";

export function SystemDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const page = useSelector(selectPage, shallowEqual);
  const pageData = page.data;
  let scopesTable = [];

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchSystemsDetailsByIDAPI(params.id, 10, null, true));
    }
  }, [dispatch, params.id]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const columns = [
    gridHeader(
      "System Name",
      "displayName",
      (value) => value,
      null,
      "",
      null,
      null,
      null,
      "id"
    ),
    gridHeader(
      "Scopes",
      "[scopes]",
      (value, row) =>
        row?.scopes?.length > 1 ? (
          <div>
            <s-table-container class="no-border">
              <table className="row-height-sm borderless">
                <tbody>
                  {Object.keys(row.scopes).map((v, idx) => {
                    scopesTable.push(
                      <tr key={row.scopes[idx]}>
                        <td className="pad-left-none">
                          {row.scopes[idx].scope}
                        </td>
                      </tr>
                    );

                    const returnMap = scopesTable;
                    scopesTable = [];
                    return returnMap;
                  })}
                </tbody>
              </table>
            </s-table-container>
          </div>
        ) : (
          row.scopes[0] && <span>{row.scopes[0].scope}</span>
        ),
      null,
      ""
    ),
    gridHeader(
      "Created",
      "meta",
      (value) => (value ? getFormattedDate(value.created, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    ),
    gridHeader(
      "Modified",
      "meta",
      (value) => (value ? getFormattedDate(value.lastModified, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    ),
  ];

  let table = "";
  if (loading) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData.length !== 0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  }
  return (
    <div>
      <Row>
        <Col>{table}</Col>
      </Row>
    </div>
  );
}
