import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import { getFormattedDate } from "../../shared/Utils.js";

import {
  selectPage,
  selectIsLoading,
  fetchSystemsRolesAPI,
} from "../../app/systemSlice.js";

export function SystemRoles() {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { systemName } = location?.state ? location.state : [];
  const loading = useSelector(selectIsLoading);
  const [submitting, setSubmitting] = useState(false);
  const page = useSelector(selectPage, shallowEqual);
  const paginateData = page.paginate;
  let permissionsTable = [];

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchSystemsRolesAPI(params?.id, 10, null, true));
    }
  }, [dispatch, params?.id]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page.data;

  const handlePaginate = async (e) => {
    if (
      e.detail.currentPage !== 0 &&
      paginateData.totalRecords > 0 &&
      e.detail.flag !== ""
    ) {
      setSubmitting(true);
      dispatch(
        fetchSystemsRolesAPI(
          params?.id,
          e.detail.rowsPerPage,
          e.detail.startIndex,
          false,
          e.detail
        )
      );
      setSubmitting(false);
    }
  };

  const columns = [
    gridHeader(
      "Role",
      "displayName",
      (value) => value,
      null,
      "",
      null,
      null,
      null,
      "id"
    ),
    gridHeader("Description", "description", (value) => value, null, ""),
    gridHeader(
      "Permissions",
      "permissions",
      (value, row) =>
        row?.permissions?.length > 1 ? (
          <div>
            <s-table-container class="no-border">
              <table className="row-height-sm borderless">
                <tbody>
                  {Object.keys(row?.permissions).map((v, idx) => {
                    permissionsTable?.push(
                      <tr key={row?.permissions[idx]}>
                        <td className="pad-left-none">
                          {row?.permissions[idx]}
                        </td>
                      </tr>
                    );

                    const returnMap = permissionsTable;
                    permissionsTable = [];
                    return returnMap;
                  })}
                </tbody>
              </table>
            </s-table-container>
          </div>
        ) : (
          row?.permissions?.length > 0 &&
          row?.permissions[0] && <span>{row?.permissions[0]}</span>
        ),
      null,
      "",
      null,
      null,
      null,
      "[permissions]"
    ),
    gridHeader(
      "Created",
      "meta",
      (value) => (value ? getFormattedDate(value?.created, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    ),
    gridHeader(
      "Modified",
      "meta",
      (value) => (value ? getFormattedDate(value?.lastModified, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    ),
  ];

  let table = "";
  if (loading || submitting) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData.length !== 0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  } else if (pageData.length === 0) {
    table = (
      <React.Fragment>
        <div className="margin-top-sm">
          <div className="font-semibold font-normal text-md">
            No roles are found
          </div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div>
      <Row>
        <Col className="pad-bottom-none">
          <h3 className="margin-bottom-none">{systemName} roles</h3>
          <hr />
        </Col>
        <Col>
          {table}
          {pageData.length > 0 ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginateData.rowsPerPage}
              totalRecords={paginateData.totalRecords}
              startIndex={paginateData.startIndex}
              onSPaginate={(e) => {
                handlePaginate(e);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
