import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { getSessionAsync, selectSession } from "./app/sessionSlice";
import { Loader, AuiIeBanner, AuiHeader } from "@avalara/skylab-react";
import routes from "./Routes";
import { dummyUserData, logoutURL } from "../src/shared/Constants";

function App() {
  const dispatch = useDispatch();
  const session = useSelector(selectSession, shallowEqual);

  useEffect(() => {
    dispatch(getSessionAsync());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      sessionStorage.clear();
    };
  }, []);

  let appDOM = null;

  if (session.isLoading) {
    appDOM = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h1>Loading ...</h1>
        <Loader id="page-loader" class="large" aria-live="polite" loading />
      </div>
    );
  } else {
    appDOM = (
      <div className="">
        <RouterProvider router={routes} />
      </div>
    );
  }

  return (
    <>
      <AuiIeBanner />
      <AuiHeader
        productName="IAM Data Manager"
        user={JSON.stringify(dummyUserData)}
        logouturl={logoutURL}
      />
      {appDOM}
    </>
  );
}
export default App;
