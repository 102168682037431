import React from "react";

import useRowSelection from "./useRowSelection";
import useId from "../hooks/useId";
import AlertRow from "./AlertRow";
import { HeaderSortable } from "@avalara/skylab-react";
import "./data-grid.css";

function getAttributes(
  source,
  sourceProperty,
  destination,
  destinationProperty,
  ...args
) {
  const merge = {};

  if (source[sourceProperty] && typeof source[sourceProperty] === typeof "") {
    merge[destinationProperty] = source[sourceProperty];
  }

  if (
    source[sourceProperty] &&
    typeof source[sourceProperty] === typeof Function
  ) {
    merge[destinationProperty] = source[sourceProperty].apply(source, args);
  }

  return Object.assign({}, destination, merge);
}

const DataGrid = React.memo((props) => {
  const [getCurrentFormId, getNextFormId] = useId();
  const options = Object.assign(
    {},
    { id: "id", allowRowSelect: false },
    props.options
  );
  const columns = [].concat(props.columns);
  const rows = [].concat(props.rows);
  /* sortColumn: type array
     sortColumn[0]: gives active sorted column id
     sortColumn[1]: specifies order either true(for asc) or false(for desc) */
  const sortColumn = props.sortColumn ? props.sortColumn : null;
  const [selected, handleSelectAll, handleSelectRow] = useRowSelection(
    options,
    rows,
    props.onChangeSelection
  );
  let alertRow = null;

  function getTableHead(options, columns) {
    const ths = columns.map((column, index) => {
      const attributes = getAttributes(
        column,
        "headerClassName",
        {},
        "className",
        column,
        columns
      );

      if (column.sortFunction && column.id && sortColumn) {
        return (
          <th key={index + 1} {...attributes}>
            <HeaderSortable
              id={column.id}
              ascending={sortColumn[1]}
              descending={sortColumn[0] === column.id && !sortColumn[1]}
              active={sortColumn[0] === column.id}
              onSSort={(e) => {
                column.sortFunction(e);
              }}
            >
              {column.label}
            </HeaderSortable>
          </th>
        );
      }
      return (
        <th key={index + 1} {...attributes}>
          {column.label}
        </th>
      );
    });

    if (options.showRowSelect) {
      ths.unshift(
        <th key="0" className="dg-checkbox">
          <input
            id={getNextFormId()}
            className="margin-right-sm"
            type="checkbox"
            onChange={handleSelectAll}
          />
          <label
            htmlFor={getCurrentFormId()}
            className="font-light margin-bottom-sm"
          ></label>
        </th>
      );
    }

    return <tr>{ths}</tr>;
  }

  function getTableBody(options, columns, rows) {
    if (rows.length === 0) {
    }
    return rows.map((row, index) => getTableRow(options, columns, row, index));
  }

  function getTableRow(options, columns, row, index) {
    const tds = columns.map((column, index) => {
      alertRow = null;
      let value = "-";
      if (typeof column.property === typeof {}) {
        value = "";
        value += column.property.map((val) => {
          return row[val];
        });
      } else if (
        column.alertRowContent !== null &&
        column.format &&
        typeof column.format === typeof Function
      ) {
        value = row[column.property];
        if (!value) {
          let alertContent = column.alertRowContent(value, row, columns);
          alertRow = (
            <AlertRow
              content={alertContent}
              colSpan={columns.length}
            ></AlertRow>
          );
        }
      } else if (
        row[column.property] + "" !== "null" ||
        row[column.property] + "" !== "undefined"
      ) {
        value = row[column.property];
      }

      const attributes = getAttributes(
        column,
        "className",
        {},
        "className",
        value,
        row,
        column,
        columns
      );
      if (column.format && typeof column.format === typeof Function) {
        value = column.format(value, row, columns, index);
      }

      return (
        <td key={index + 1} {...attributes}>
          {value}
        </td>
      );
    });

    if (options.showRowSelect) {
      const checked = selected.includes(String(row[options.id]));

      tds.unshift(
        <td key="0" className="dg-checkbox">
          <input
            id={getNextFormId()}
            type="checkbox"
            checked={checked}
            onChange={handleSelectRow}
            data-rowid={row[options.id]}
            aria-label="select row"
          />
          <label htmlFor={getCurrentFormId()}>&#8205;</label>
        </td>
      );
    }
    return (
      <React.Fragment key={props.getKey(row)}>
        <tr>{tds}</tr>
        {alertRow}
      </React.Fragment>
    );
  }

  const dataGrid = (
    <table className={`dg-data-grid ${!props.inContainer ? "card-table" : ""}`}>
      <thead>{getTableHead(options, columns)}</thead>
      <tbody>{getTableBody(options, columns, rows)}</tbody>
    </table>
  );

  return (
    <React.Fragment>
      {!props.inContainer ? (
        <div className="dg-data-grid-block">{dataGrid}</div>
      ) : (
        dataGrid
      )}
    </React.Fragment>
  );
});

export default DataGrid;
