export const logoutURL = `https://${process.env.REACT_APP_IDENTITY_HOST}/connect/endsession`;

export const dummyUserData = {
  accountId: "1213123",
  username: "iam-user",
  givenName: "IAM User",
  familyName: "User",
  email: "iam-user@avalara.com",
  isLoggedIn: true,
  isTechSupport: false,
  name: "IAM User",
  locale: "",
};
