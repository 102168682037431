import { createBrowserRouter } from "react-router-dom";
import Login from "./features/session/login";
import Logout from "./features/session/logout";
import Overview from "./features/overview/Overview";
import { SystemList } from "../src/features/system/systemList";
import { SystemDetails } from "./features/system/systemDetails";
import { SystemRoles } from "./features/system/systemRoles";
import { OrganizationList } from "./features/organization/organizationList";
import { OrganizationDetails } from "./features/organization/organizationDetails";
import ErrorPage from "./errorhandling/Error";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Overview />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth/callback",
    element: <Login />,
  },
  {
    path: "/auth/logout",
    element: <Logout />,
  },
  {
    path: "/system",
    element: <SystemList />,
  },
  {
    path: "/system/roles/:id",
    element: <SystemRoles />,
  },
  {
    path: "/system/:id",
    element: <SystemDetails />,
  },
  {
    path: "/org",
    element: <OrganizationList />,
  },
  {
    path: "/org/:id",
    element: <OrganizationDetails />,
  },
]);

export default routes;
